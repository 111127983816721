import React from 'react';
import { useCookies } from 'react-cookie';
import { gql } from '@apollo/client';
import {
  Button,
  CardContent,
  Divider,
  Box,
  Stack,
  Typography,
  Card,
  styled,
  Link,
} from '@mui/material';
import { TextField } from '@mui/material';
import { validatePassword } from './../tools/validatePassword';
import theme from '../../theme';
import Forbidden from '../forbidden/Forbidden';
import { ImageInput } from '../inputs/Image';
import { Center } from '../../informationPage/Information';
import BlurryBackground from '../blurryBackground/BlurryBackground';
import client from '../../graphqlApollo';
import { showToast } from './../tools/toast';
import Navbar from '../navbar/Navbar';

export default function Register() {
  const [cookies, setCookie] = useCookies(['token', 'theme']);
  let businessName = '';
  let email = '';
  let password = '';
  let confPassword = '';
  let businessLogo = '';
  const profilePic = '';

  const setBusinessLogo = (value: string) => {
    businessLogo = value;
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!email || !password || !confPassword || !businessName || !businessLogo) {
      showToast('error', 'Veuillez remplir tous les champs');
      return;
    }

    if (password !== confPassword) {
      showToast('error', 'Les mots de passe ne correspondent pas');
      return;
    }

    if (!validatePassword(password)) return;

    const qr = gql(`
      query {
        register(
          email: "${email}", 
          password: "${password}", 
          businessName: "${businessName}", 
          business_logo: "${businessLogo}"
        ) {
          status
          userMessage
          devMessage
          content {
            email
            access_token
          }
        }
      }
    `);

    try {
      const data = await client.query({ query: qr });
      const response = data.data.register;

      if (response.status === 200) {
        setCookie('token', response.content.access_token, { path: '/' });
        window.location.replace('/confirm_registration');
      } else {
        showToast('error', response.userMessage);
      }
    } catch (error) {
      showToast('error', 'Une erreur inattendue est survenue');
    }
  };

  const ResponsiveCard = styled(Card)(({ theme }) => ({
    width: '100vp',
    [theme.breakpoints.up('md')]: {
      width: '67vp',
    },
    display: 'flex',
    maxHeight: '80vh',
    overflowY: 'auto',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
  }));

  const NameInputText = styled(TextField)(({ theme }) => ({
    width: '90vw',
    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
    marginBottom: theme.spacing(2),
  }));

  const RegisterButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30vw',
    },
    marginBottom: theme.spacing(2),
  }));

  const LeftAlignedStack = styled(Stack)({
    alignItems: 'center',
  });

  return !cookies.token ? (
    <React.Fragment>
      <Navbar />
      <BlurryBackground />
      <Center width={'100%'} height={'100%'} flexDirection={'column'}>
        <Typography
          variant="h1"
          color="green.main"
          align="center"
          sx={{ fontWeight: 'bold', fontSize: { xs: '32px', md: '48px' } }}
        >
          Bienvenue chez LocalShirt
        </Typography>
        <Box width={{ xs: '100%', sm: '80%', md: '67%' }}>
          <Center>
            <ResponsiveCard variant="outlined" color="white">
              <CardContent>
                <Typography
                  variant="h2"
                  color="gray.main"
                  align="left"
                  sx={{ fontSize: { xs: '24px', md: '32px' } }}
                >
                  Informations de mon Entreprise
                </Typography>
                <Divider
                  sx={{
                    border: '1px solid',
                    borderColor: 'gray.main',
                    marginBottom: '3vw',
                    width: { xs: '70vw', md: '30vw' },
                  }}
                />
                <LeftAlignedStack>
                  <Box
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent={'space-between'}
                    alignItems="flex-start"
                    width="100%"
                    sx={{ marginBottom: '1vw' }}
                  >
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      width={{ xs: '100%', md: '60%' }}
                    >
                      <Typography color="gray.main" align="left">
                        Nom de l'Entreprise
                      </Typography>
                      <NameInputText
                        InputProps={{ style: { height: '42px' } }}
                        id="lastInNameInputText"
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          businessName = e.target.value;
                        }}
                      />
                      <Typography color="gray.main" align="left">
                        Email
                      </Typography>
                      <NameInputText
                        InputProps={{ style: { height: '42px' } }}
                        id="email"
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          email = e.target.value;
                        }}
                      />
                      <Typography color="gray.main" align="left">
                        Mot de Passe
                      </Typography>
                      <NameInputText
                        InputProps={{ style: { height: '42px' }, type: 'password' }}
                        id="password"
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          password = e.target.value;
                        }}
                      />
                      <Typography color="gray.main" align="left">
                        Confirmation du mot de passe
                      </Typography>
                      <NameInputText
                        InputProps={{ style: { height: '42px' }, type: 'password' }}
                        id="passwordConfirmation"
                        variant="outlined"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          confPassword = e.target.value;
                        }}
                      />
                    </Box>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent="center"
                      width={{ xs: '100%', md: '35%' }}
                      pl={{ xs: 0, md: 10 }}
                    >
                      <ImageInput
                        prevLogo={''}
                        title={'Logo'}
                        edit={true}
                        setImageString={setBusinessLogo}
                        imageString={profilePic}
                        id="1"
                      />
                    </Box>
                  </Box>
                </LeftAlignedStack>
                <Box display={'flex'} justifyContent={'center'}>
                  <RegisterButton variant="contained" onClick={handleSubmit}>
                    S'inscrire
                  </RegisterButton>
                </Box>
              </CardContent>
            </ResponsiveCard>
          </Center>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'80vw'}
          justifyContent={'center'}
          sx={{ marginTop: '1vh' }}
        >
          <Typography variant="h2" sx={{ fontSize: { xs: '18px', md: '24px' } }}>
            Déja un compte ?
          </Typography>
          <Link underline="none" variant="h2" color={theme.palette.green.main} href={'/login'}>
            Se connecter
          </Link>
        </Box>
      </Center>
    </React.Fragment>
  ) : (
    <Forbidden />
  );
}
