import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

interface CircularProgressWithLabelProps extends CircularProgressProps {
    value: number;
    size?: number;
    textFontSize?: number;
    thickness?: number;
    backgroundColor?: string;
}

/**
 * A CircularProgressWithLabel, component that display the score as % in the middle of a circle that will fill up based on the %
 *
 * @component
 * @param {CircularProgressWithLabelProps} props - The component accepts color, content, shadow and onClick as props
 * @param {number} props.value - The value of the score of the article
 * @param {number} props.size - The size of the circle
 * @param {number} props.textFontSize - The text font size of the score
 * @param {number} props.thickness - The thickness of the circle
 * @param {string} props.backgroundColor - The color of the background of the uncompleted circle
 * @returns {JSX.Element} The rendered component
 */

export function getProgressBarColor(scoreValue: number) {
    if (scoreValue <= 33.33) {
        return '#C85C5C';
    } else if (scoreValue <= 66.66) {
        return '#C9B673';
    } else {
        return '#94C973';
    }
}

export default function CircularProgressWithLabel(props: CircularProgressWithLabelProps) {
    const { size = 100, textFontSize = 30, thickness = 6, backgroundColor = 'white', ...rest } = props;

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', width: size, height: size }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
            >
                <CircularProgress
                    variant="determinate"
                    size={size}
                    thickness={thickness}
                    value={100}
                    style={{ color: backgroundColor }}
                    sx={{
                        '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round',
                        },
                    }}
                />
            </Box>

            <CircularProgress
                variant="determinate"
                size={size}
                thickness={thickness}
                {...rest}
                style={{ color: getProgressBarColor(props.value) }}
                sx={{
                    '& .MuiCircularProgress-circle': {
                        strokeLinecap: 'round',
                    },
                }}
            />

            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    sx={{ fontSize: textFontSize }}
                >
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}