import { Box, Button, Menu, Stack, Typography, useMediaQuery } from '@mui/material';
import { useCookies } from 'react-cookie';
import { Center } from '../../informationPage/Information';
import localShirtFullLogoGreen from '../../assets/green_logo.svg';
import * as Typo from '../../components/Typography/typographyStyle';
import whiteLogo from './../../assets/white_logo.svg';
import AccountButton from '../myAccount/accountButton';
import MenuIcon from '@mui/icons-material/Menu';
import theme from '../../theme';
import { useState } from 'react';

function MobileNav() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: '10px',
            paddingRight: '50px',
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) =>
            setAnchorEl(event.currentTarget as HTMLElement)
          }
        >
          <MenuIcon color="action" sx={{ width: '50px', height: '50px' }} />
        </Box>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <HamburgerMenu />
        </Menu>
      </Box>
    </>
  );
}

export function HamburgerMenu() {
  return (
    <>
      <Box sx={{ padding: '20px' }}>
        <Typography
          style={Typo.poppinsUnderTitleSB15}
          sx={{ marginBottom: '15px', cursor: 'pointer' }}
          onClick={() => window.location.replace('/login')}
        >
          Espace Partenaire
        </Typography>
        <Typography
          style={Typo.poppinsUnderTitleSB15}
          sx={{ cursor: 'pointer' }}
          onClick={() => window.location.replace('/searchAlternative')}
        >
          analyser un article
        </Typography>
      </Box>
    </>
  );
}

export default function Navbar() {
  const [cookies] = useCookies(['token', 'theme']);

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box width="100%" zIndex={1000} position="relative">
      <Stack width={'100%'} direction={'row'} justifyContent={'space-between'} paddingY={'1.5vh'}>
        {
          <Box>
            <Button
              style={{ border: 'none', background: 'none' }}
              onClick={() => window.location.replace('/')}
            >
              {window.location.pathname === '/catalog' ? (
                <img
                  src={whiteLogo}
                  alt="White Logo"
                  style={{
                    marginLeft: '290px',
                    marginTop: '5px',
                    width: '250px',
                    height: 'auto',
                  }}
                />
              ) : (
                <img src={localShirtFullLogoGreen} alt="Green Logo" style={{ marginLeft: '2vw' }} />
              )}
            </Button>
          </Box>
        }

        {!cookies.token ? (
          isLargeScreen ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              marginRight={'2vw'}
            >
              <Typography
                sx={{
                  marginRight: '2vw',
                  ':hover': { cursor: 'pointer' },
                  whiteSpace: 'nowrap',
                }}
                onClick={() => window.location.replace('/login')}
                variant="h3"
              >
                Espace Partenaire
              </Typography>

              <Center
                bgcolor={'green.main'}
                borderRadius={'5px'}
                width={'100%'}
                height={'70%'}
                paddingX={'1vw'}
                onClick={() => window.location.replace('/searchAlternative')}
                sx={{ ':hover': { cursor: 'pointer' } }}
              >
                <Typography variant={'button'} color={'white.light'}>
                  Analyser un article
                </Typography>
              </Center>
            </Box>
          ) : (
            <MobileNav />
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '2vw',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                top: '-2px',
              }}
            >
              <AccountButton />
            </Box>
            <Center
              bgcolor={'green.main'}
              borderRadius={'5px'}
              width={'100%'}
              height={'70%'}
              paddingX={'1vw'}
              onClick={() => window.location.replace('/searchAlternative')}
              sx={{
                ':hover': { cursor: 'pointer' },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant={'button'} color={'white.light'}>
                Analyser un article
              </Typography>
            </Center>
          </Box>
        )}
      </Stack>
    </Box>
  );
}
