import React, { Fragment } from 'react';
import Ball from '../assets/blurred-green-ball.svg';
import { useQuery } from '@apollo/client';
import client from '../graphqlApollo';
import { gql } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid2'
import { styled, keyframes } from '@mui/material/styles';
import { showToast } from "../components/tools/toast";
import Navbar from "../components/navbar/Navbar";
import { ButtonAccount } from '../RegistrationConfirmation/RegistrationConfirmation';

const pulseBounceMove = keyframes`
  0% { transform: scale(1) translateY(0) translateX(0); }
  25% { transform: scale(1.1) translateY(-100px) translateX(0); }
  50% { transform: scale(1) translateY(0) translateX(100px); }
  75% { transform: scale(1.1) translateY(-100px) translateX(0); }
  100% { transform: scale(1) translateY(0) translateX(0); }
`;

const BodyContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const FixedNavbar = styled(Navbar)({
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1001,
});

const FrameContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  marginTop: '64px',
  position: 'relative',
});

const GreenBall1 = styled('img')({
  position: 'absolute',
  left: '-300px',
  animation: `${pulseBounceMove} 15s ease-in-out infinite`,
});

const GreenBall2 = styled('img')({
  position: 'absolute',
  left: '1300px',
  top: '200px',
  animation: `${pulseBounceMove} 20s ease-in-out infinite`,
});

function DevConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('email');


  const { loading, error, data } = useQuery(
    gql`
      query confirmDev($token: String!) {
        confirmDev(token: $token) {
          status
          devMessage
          userMessage
          content {
            email
            access_token
          }
        }
      }
    `,
    {
      variables: { token },
      client,
    }
  );

  if (loading) return <p>Loading...</p>;
  if (error) {
    showToast('error', 'Erreur lors de la récupération du fichier Markdown' + (error.message));
    return <p>Error: {error.message}</p>;
  }


  const navigateInformationPage = () => {
    navigate('/information');
  };

  return (
    <Fragment>
      <BodyContainer>
        <FixedNavbar />
        <FrameContainer>
          <GreenBall1 src={Ball} alt="none" />
          <GreenBall2 src={Ball} alt="none" />
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: 'center' }}
          >
            <Grid size={{ xs: 12, sm: 12 }} >
              <Typography variant="h1">Bienvenue parmi nous,</Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 12 }} >
              <Typography variant="h2">Localshirt vous souhaite la bienvenue en tant que developpeur !</Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 12 }} >
              <ButtonAccount variant="contained" onClick={navigateInformationPage}>
                Commencez l'aventure!
              </ButtonAccount>
            </Grid>
          </Grid>
        </FrameContainer>
      </BodyContainer>
    </Fragment>
  );
}

export default DevConfirmation