import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LeftPanel from './LeftPanel';
import ItemCard from './ItemCard';
import Divider from '@mui/material/Divider';
import { Button } from '@mui/material';
import { GreenButton } from '../../components/buttons/buttons';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import Loading from '../../components/loading/Loading';
import BlurryBackground from '../../components/blurryBackground/BlurryBackground';
import Forbidden from '../../components/forbidden/Forbidden';
import { showToast } from "../../components/tools/toast";
import Navbar from "../../components/navbar/Navbar";
import { Center } from "../../informationPage/Information";
import AccountButton from "../../components/myAccount/accountButton";
import Tshirt from "../../assets/tshirt.svg"


type User = {
  email: string;
  password: string;
  name: string;
  lastName: string;
  businessRole: string;
  businessContact: string;
  businessDescription: string;
  businessName: string;
  isConfirmed: boolean;
  isDeveloper: boolean;
};

export type Items = {
  title: string;
  photo: string;
  id: string;
  price: number;
  desc: string;
  score: number;
  type: string;
  redirectnumber: number;
  lastshown: Date;
  lastclick: Date;
  ethicalscore: number;
  ecologicalscore: number;

};

const Catalog = () => {
  const [isLoading, setLoading] = useState(false);
  const [cookies] = useCookies(['token']);
  const [itemList, setItemList] = useState<Items[]>([]);
  const [filteredItemList, setFilteredItemList] = useState<Items[]>([]);
  const [sort, setSort] = React.useState('');
  const [user, setUser] = useState<User>();


  const qr = gql(`
  query {
    getArticlePartnerUserList {
      status
      devMessage
      userMessage
      articles {
        id
        name
        country {name}
        transport {name}
        material {name}
        price
        local_score
        image
        description
        type
        ecological_score
        ethical_score
        redirect_number
        last_view
        last_click
      }
    }
  }
  `);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const articles = await getArticles();
        setItemList(articles);
        setFilteredItemList(articles);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchData();
  }, []);


  const ChangeFilter = (event: SelectChangeEvent) => {
    const selectedFilter = event.target.value;
    setSort(selectedFilter);
    
    if (selectedFilter === "1") {
      const sortedItems = [...filteredItemList].sort((a, b) => b.redirectnumber - a.redirectnumber);
      setFilteredItemList(sortedItems);
    } else if (selectedFilter === "2") {
      const sortedItems = [...filteredItemList].sort((a, b) => b.score - a.score);
      setFilteredItemList(sortedItems);
    } else if (selectedFilter === "3") {
      const sortedItems = [...filteredItemList].sort((b, a) => b.lastshown.getTime() - a.lastshown.getTime()).reverse();
      setFilteredItemList(sortedItems);
    } else if (selectedFilter === "4") {
      const sortedItems = [...filteredItemList].sort((b, a) => b.lastclick.getTime() - a.lastclick.getTime()).reverse();
      setFilteredItemList(sortedItems);
    } else {
      setFilteredItemList([...itemList]);
    }
    
  };

  const getArticles = async () => {
    const data = await client.query({
      query: qr, context: {
        headers: {
          authorization: "Bearer " + cookies.token,
        },
      }
    });

    if (data.data.getArticlePartnerUserList.status !== 200) {
      showToast('error', data.data.getArticlePartnerUserList.userMessage);
      return [];
    }
    const articles = data.data.getArticlePartnerUserList.articles;
    const items = [];
    for (const article of articles) {
      const item: Items = {
        title: article.name,
        photo: article.image,
        id: article.id,
        price: article.price,
        type: article.type,
        desc: article.description,
        score: article.local_score,
        ecologicalscore: article.ecological_score,
        ethicalscore: article.ethical_score,
        redirectnumber: article.redirect_number,
        lastshown: article.last_shown,
        lastclick: article.last_click
      };
      items.push(item);
    }
    return items;
  };



  const handleSearchChange = (searchQuery: string) => {

    const filteredItems = searchQuery.trim() === ''
      ? itemList
      : itemList.filter((item) => {
        return item.title.toLowerCase().includes(searchQuery.toLowerCase());
      });

    setFilteredItemList(filteredItems);
  };

  const handleButtonClick = (category: string) => {
    const filteredItems = itemList.filter((item) => {
      const itemNameMatch = item.title?.toLowerCase().includes(category.toLowerCase());
      const itemDescriptionMatch = item.desc?.toLowerCase().includes(category.toLowerCase());
      const itemTypeMatch = item.type?.toLowerCase().includes(category.toLowerCase());

      return itemNameMatch || itemDescriptionMatch || itemTypeMatch;
    });
    setFilteredItemList(filteredItems);
  };

  const addArticleRedirect = () => {
    window.location.replace('/addArticle');
  };

  const dividerStyle = {
    borderRight: '1px solid',
    color: '#5E5E5E',
  };

  return (!cookies.token ? <Forbidden /> :
    <>
      <BlurryBackground />
      <LeftPanel onSearchChange={handleSearchChange} onButtonClick={handleButtonClick} />
      <Loading open={isLoading} />
      <Box sx={{
        position: 'absolute',
        top: '0',
        left: '370px',
        width: '1510px',
        height: '100vh',
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '15px'
        }} >
          <Typography sx={{ fontSize: '50px', paddingBottom: '20px' }}>Catalogue</Typography>
          {!cookies.token ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              marginRight={'2vw'}
            >
              {/* Espace Partenaire */}
              <Typography
                sx={{
                  marginRight: '2vw',
                  ":hover": { cursor: "pointer" },
                  whiteSpace: 'nowrap',
                }}
                onClick={() => window.location.replace('/login')}
                variant="h3"
              >
                Espace Partenaire
              </Typography>

              {/* Analyser un article */}
              <Center
                bgcolor={'green.main'}
                borderRadius={'5px'}
                width={'100%'}
                height={'70%'}
                paddingX={'1vw'}
                onClick={() => window.location.replace('/login')}
                sx={{ ":hover": { cursor: "pointer" } }}
              >
                <Typography variant={'button'} color={'white.light'}>Analyser un article</Typography>
              </Center>
            </Box>
          ) : (
            <AccountButton />
          )}
        </Box>

        <Divider orientation="horizontal" flexItem sx={dividerStyle} />

        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: '15px'
        }} >
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: '10px', justifyContent: 'center' }}>
            <Typography sx={{ fontWeight: '600', fontSize: '20px' }}>Trier par </Typography>
            <FormControl sx={{ m: 1, minWidth: 120, bgcolor: '#C8C8C8', borderRadius: '10px', '.MuiOutlinedInput-notchedOutline': { border: 0 } }} size="small">
              <InputLabel id="item-card-filtre">Filtre</InputLabel>
              <Select
                value={sort}
                onChange={ChangeFilter}
                autoWidth
                label="Filtre"
              >
                <MenuItem value="">
                  <em>Filtre</em>
                </MenuItem>
                <MenuItem value={"1"}>Nombre de redirections</MenuItem>
                <MenuItem value={"2"}>Meilleur score</MenuItem>
                <MenuItem value={"3"}>Dernière apparition</MenuItem>
                <MenuItem value={"4"}>Dernière redirection</MenuItem>
              </Select>
            </FormControl>
          </Box >
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', paddingTop: '15px', justifyContent: 'center' }}>
            <Button variant="contained" size="small" sx={{ ...GreenButton(), width: '220px', height: '50px', backgroundColor: 'primary.main' }} onClick={addArticleRedirect}>
              <Typography color={'white.light'}>Ajouter un article</Typography>
            </Button>
          </Box>

        </Box>
        <Box sx={{ paddingBottom: '60px', }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '50px', paddingLeft: '30px' }}>
            {filteredItemList.length === 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={Tshirt} alt="No items" style={{ maxWidth: '150px', marginBottom: '10px' }} />
                <Typography variant="h6" sx={{ color: 'gray', textAlign: 'center', margin: '2vh' }}>
                  Votre catalogue semble vide... <br></br>
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => window.location.replace('/addArticle')}
                  sx={{
                    bgcolor: 'green.main',
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    paddingX: '1vw',
                    maxWidth: '300px',
                    ":hover": {
                      cursor: "pointer",
                      bgcolor: 'green.dark'
                    }
                  }}
                >
                  <Typography color="white.light">
                    Ajouter un premier article
                  </Typography>
                </Button>

              </Box>

            ) : (
              filteredItemList.map((item, index) => (
                <div key={item.id} style={{ marginBottom:  '30px' }}>
                  <ItemCard
                    key={item.id}
                    title={item.title}
                    type={item.type}
                    photo={item.photo}
                    id={item.id}
                    price={item.price}
                    desc={item.desc}
                    score={item.score}
                    ecologicalscore={item.ecologicalscore}
                    ethicalscore={item.ethicalscore}
                    redirectnumber={item.redirectnumber}
                    lastshown={item.lastshown}
                    lastclick={item.lastclick}
                  />
                </div>
              ))
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Catalog;
