import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';

interface NameCardProps {
  photo: string;
  text: React.ReactNode;
  isPhotoRight?: boolean;
}

const NameCard: React.FC<NameCardProps> = ({ photo, text, isPhotoRight = false }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: isPhotoRight ? 'row-reverse' : 'row',
        border: '2px solid white',
        backgroundColor: 'transparent',
        borderRadius: '5px',
        padding: '16px',
        width: '100vw',
        maxWidth: '1300px',
        margin: '20px'
      }}
    >
      {/* Photo ronde */}
      <Avatar
        src={photo}
        alt="Profile Photo"
        sx={{
          width: '5vw',
          height: '5vw',
          marginLeft: isPhotoRight ? '16px' : '0',
          marginRight: isPhotoRight ? '0' : '16px',
        }}
      />

      {/* Texte */}
      <Typography variant="body1" color="white">
        {text}
      </Typography>
    </Box>
  );
};

export default NameCard;
