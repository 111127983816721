import client from '../../graphqlApollo';
import { gql } from '@apollo/client';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import React, { useState } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Box, Button, Card, CardContent, Link, TextField, Typography, styled } from '@mui/material';
import Loading from '../loading/Loading';
import { Center } from "../../informationPage/Information";
import BlurryBackground from '../blurryBackground/BlurryBackground';
import { showToast } from './../tools/toast';
import Navbar from '../navbar/Navbar';

export type Dispatcher<S> = Dispatch<SetStateAction<S>>;

export default function Login() {
  const [isLoading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(['token', 'theme']);

  let email = '';
  let password = '';
  const navigate = useNavigate();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const qr = gql(`
      query {
        login(email: "${email}", password: "${password}") {
          status
          userMessage
          devMessage
          content {
            email
            access_token
          }
        }
      }
    `);
    e.preventDefault();
    setLoading(true);
    const data = await client.query({
      query: qr,
    });
    if (data.data.login.status !== 200) {
      showToast('error', data.data.login.userMessage);
      setLoading(false);
    }
    if (data.data.login.content != null) {
      setCookie('token', data.data.login.content.access_token, { path: '/' });
      window.location.replace('/mon-compte');
    }
  };

  const CenteredCardContainer = styled(Box)(({ theme }) => ({
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      height: '30vw',
    },
  }));

  const ConnexionButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.light,
    marginBottom: "3vh",
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      padding: theme.spacing(1.5),
    },
  }));

  const RegisterButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: theme.palette.beige.main,
    color: theme.palette.gray.main,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '16px',
      padding: theme.spacing(1.5),
    },
  }));

  const ResponsiveCard = styled(Card)(({ theme }) => ({
    width: '90vw',
    [theme.breakpoints.up('sm')]: {
      width: '50vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '20vw',
    },
    display: 'flex',
    overflowY: 'auto',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    backgroundColor: theme.palette.green.dark,
  }));

  const Input = styled(TextField)(({ theme }) => ({
    height: "42px",
    borderRadius: '10px',
    marginBottom: '20px',
    width: '100%',
    '& .MuiInputLabel-root': {
      color: 'white',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      color: 'white'
    },
    '& .MuiFilledInput-underline:before': {
      borderBottomColor: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: 'transparent',
    },
  }));

  return (
    <React.Fragment>
      <Navbar/>
      <Loading open={isLoading} />
      <Center width={'100%'} height={'100%'}>
        <CenteredCardContainer>
          <ResponsiveCard>
            <CardContent>
              <Box display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Typography
                  sx={{ fontSize: { xs: 28, md: 32 }, fontWeight: 'bold' }}
                  color="white.main"
                >
                  Espace
                </Typography>
                <Typography
                  sx={{ fontSize: { xs: 28, md: 32 }, fontWeight: 'bold', marginBottom: '8vh' }}
                  color="white.main"
                  gutterBottom
                >
                  Partenaire
                </Typography>
              </Box>
              <Box display="flex" flexDirection='column' alignItems="center" width="100%">
                <Input
                  id="login"
                  label="Identifiant"
                  variant='filled'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { email = e.target.value }}
                />
                <Input
                  id="password"
                  label="Mot de passe"
                  variant='filled'
                  type='password'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { password = e.target.value }}
                />
                <ConnexionButton variant="contained" onClick={handleSubmit}>
                  Connexion
                </ConnexionButton>
                <Link
                  style={{ marginBottom: "3vh" }}
                  color="white.light"
                  variant="h3"
                  href="/forgotPasswordEmail"
                >
                  Mot de passe oublié ?
                </Link>
                <RegisterButton onClick={() => { navigate('/register') }} variant="contained">
                  Créer un compte
                </RegisterButton>
              </Box>
            </CardContent>
          </ResponsiveCard>
        </CenteredCardContainer>
      </Center>
    </React.Fragment>
  );
}
