import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Typography } from '@mui/material';
import { ImageInput } from '../../components/inputs/Image';
import TextInput from '../../components/inputs/Text';
import { showToast } from '../../components/tools/toast';

const LocalImageAnalyze = () => {
  const [uploadedImage, setUploadedImage] = useState<string>('');
  const [inputUrl, setInputUrl] = useState<string>('');
  const navigate = useNavigate();

  const handleRedirect = () => {
    if (uploadedImage) {
      navigate(`/result`, { state: { base64Image: uploadedImage } });
    } else {
      showToast('error', 'Veuillez télécharger une image.');
    }
  };

  const handleTextInputRedirect = () => {
    if (inputUrl.trim()) {
      navigate('/result', { state: { url: inputUrl.trim() } });
    } else {
      showToast('error', 'Veuillez entrer une URL valide.');
    }
  };

  return (
    <Box
      sx={{
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '80px',
      }}
    >
      <Typography variant="h1" fontWeight="bold" marginBottom="40px" textAlign="center">
        Renseignez ici une image de produit textile pour découvrir nos alternatives écoresponsables
        !
      </Typography>

      <ImageInput
        setImageString={(value: string) => setUploadedImage(value)}
        imageString={uploadedImage || 'https://via.placeholder.com/200'}
        title="Téléchargez une image"
        edit={true}
        id="textile-image-upload"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleRedirect}
        sx={{ marginTop: '20px', marginLeft: '-18px' }}
      >
        Trouver des alternatives
      </Button>

      <TextInput
        title="Ou entrez une URL"
        placeholder="Ex : https://example.com/image.jpg"
        showContent={true}
        width={50}
        tag="url-input"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputUrl(e.target.value)}
      />


      <Button
        variant="contained"
        color="primary"
        onClick={handleTextInputRedirect}
        sx={{ marginTop: '20px', marginLeft: '-18px' }}
        disabled={!inputUrl.trim()}
      >
        Utiliser l'URL saisie
      </Button>
    </Box>
  );
};

export default LocalImageAnalyze;
