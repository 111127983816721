import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import {useNavigate} from "react-router-dom";
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { Container, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TextField, styled } from '@mui/material';
import { Modal, Box, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useCookies } from 'react-cookie';
import { showToast } from '../../components/tools/toast';

interface User {
  id: string;
  email: string;
  name: string;
  lastName: string;
  businessRole: string;
  businessName: string;
  isConfirmed: boolean;
  isDeveloper: boolean;
  isAdmin: boolean;
}

const GET_ALL_USERS = gql(`
    query {
        getAllUsers {
            status
            users {
                email
                businessName
                isConfirmed
                isDeveloper
            }
        }
    }
`);
const UPDATE_ADMIN_USER = gql`
  mutation UpdateAdminUser(
    $email: String!,
    $businessName: String!,
    $isConfirmed: Boolean!,
    $isDeveloper: Boolean!,
  ) {
    updated_admin_user(
      email: $email,
      businessName: $businessName,
      isConfirmed: $isConfirmed,
      isDeveloper: $isDeveloper,

    ) {
      status, 
      userMessage,
      devMessage
    }
  }
`;

export default function AdminUsersTable() {
  const [articles, setArticles] = useState<User[]>([]);
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [isSlected, setIsSlected] = useState<boolean>(false);
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const [isreloadData, setisReloading] = useState(true);
  useEffect(() => {
    if (isreloadData) {
      getAllUsers().then((data) => {
        if (data.status !== 200) {
          navigate("/");
        }
        setArticles(data.users);
        articlesFilter = articles.filter((item: any) =>
            Object.values(item)
                .join('')
                .toLowerCase()
                .includes(search.toLowerCase())
        );
        console.log("reload data", data.users);

      });

      setisReloading(false);
    }

  }, [isreloadData]);

  const getAllUsers = async () => {
    const data = await client.query({
      query: GET_ALL_USERS,
      context: {
        headers: {
          authorization: "Bearer " + cookies.token,
        },
      }
    });
    return data.data.getAllUsers;
  };


  const handleChangeRecherche = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleEditClick = (user: User) => {
    setIsSlected(true);
    setSelectedUser(user);
  };
  const handleCloseModal = () => {
    setIsSlected(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked, value } = event.target;
    if (selectedUser) {
      if (event.target.name === "isConfirmed" || event.target.name === "isDeveloper") {
        setSelectedUser({
          ...selectedUser,
          [name]: checked
        });
      } else {
        setSelectedUser({
          ...selectedUser,
          [name]: value,
        });
      }
    }
  };


  let articlesFilter = articles.filter((item: User) =>
      Object.values(item)
          .join('')
          .toLowerCase()
          .includes(search.toLowerCase())
  );

  const handleUpdateUser = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedUser) {
      try {
        const {data} = await client.mutate({
          mutation: UPDATE_ADMIN_USER,
          variables: {
            email: selectedUser.email,
            businessName: selectedUser.businessName,
            isConfirmed: selectedUser.isConfirmed,
            isDeveloper: selectedUser.isDeveloper,
          },
          context: {
            headers: {
              authorization: "Bearer " + cookies.token,
            },
          }
        });
        setIsSlected(false);
        setSelectedUser(undefined);
        if (data.updated_admin_user.status !== 200) {
          showToast('error', data.updated_admin_user.devMessage);
        } else {
          showToast('success', data.updated_admin_user.devMessage);
          setisReloading(true);
        }
      } catch (error) {
        console.error('Error updating user:', error);
        showToast('error', "Update raté");
      }
    }
  };

  const TableCellStyled = styled(TableCell)(() => ({
    fontSize: '1rem',
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center'
  }))

  const displayBodyTable = (items: User[]) => {
    return items.map((item) => (
      <TableRow key={item.id}>
        {Object.values(item).map((value, index) => (
          index === 0 ? null :
            <TableCellStyled key={index} size='small'>
              {typeof value === 'boolean' ? (value ? <Checkbox style={{ pointerEvents: 'none' }} checked /> : <Checkbox style={{ pointerEvents: 'none' }} />) : value}
            </TableCellStyled>
        ))}
        <TableCellStyled padding="checkbox">
          <Button variant="outlined" onClick={() => handleEditClick(item)}>
            Modifier
          </Button>
        </TableCellStyled>
      </TableRow>
    ))
  }

  return (
    <Container sx={{ width: '100%' }}>
      <TextField
        label="Rechercher"
        variant="outlined"
        value={search}
        onChange={handleChangeRecherche}
        sx={{ marginBottom: '10px' }}
      />
      <TableContainer >
        <Table
          aria-labelledby="tableTitle">
          <TableHead>
            <TableRow >
              {['Email', 'Entreprise', 'Utilisateur Confirmé', 'Développeur', ''].map((columnName, index) => (
                <TableCellStyled key={index} size='small' padding='normal'>
                  {columnName}
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {displayBodyTable(articlesFilter)}
          </TableBody>
        </Table>
      </TableContainer>

      {selectedUser && isSlected && (
        <Modal open={isSlected}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
          >
            <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
              Modifier l'utilisateur
            </Typography>
            <form onSubmit={handleUpdateUser}>
              <TextField
                key="businessName"
                label="Entreprise "
                name='businessName'
                value={selectedUser.businessName}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                variant="outlined"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedUser.isConfirmed}
                    onChange={handleInputChange}
                    name="isConfirmed"
                    color="primary"
                  />
                }
                label="Is Confirmed"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedUser.isDeveloper}
                    onChange={handleInputChange}
                    name="isDeveloper"
                    color="primary"
                  />
                }
                label="Is Developer"
              />
              <Button type="submit" variant="outlined">Enregistrer</Button>
            </form>
          </Box>
        </Modal>
      )}
    </Container>
  );
}
