import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import {useNavigate} from "react-router-dom";
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { Container, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, TextField, styled } from '@mui/material';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import { showToast } from '../../components/tools/toast';

interface Article {
  id: string;
  name: string;
  email: string;
  country: { id: string, name: string }[] | string;
  material: { name: string }[];
  transport: { id: string, name: string }[] | string;
  environnement_desc: string;
  ethical_desc: string;
  description: string;
  price: string;
  image: any,
}

const GET_ALL_ARTICLES = gql(`
  query {
    getAllArticlesPartner {
      status
      devMessage
      userMessage
      articles {
        id
        name
        description
        image
        environnement_desc
        ethical_desc
        price
      }
    }
  }
`);

const UPDATE_ARTICLE = gql(`
  query UpdateArticlePartner(
    $id: String!,
    $name: String!,
    $price: String!,
    $image: String!,
    $description: String!,
    $environnement_desc: String!,
    $ethical_desc: String!,
  ) {
    updateArticlePartner(
      id: $id,
      name: $name,
      price: $price,
      image: $image,
      description: $description,
      environnement_desc: $environnement_desc,
      ethical_desc: $ethical_desc,
    ) {
      status
      devMessage
      userMessage
    }
  }
`);


export default function AdminUsersTable() {
  const [articles, setArticles] = useState<Article[]>([]);
  const [recherche, setRecherche] = useState('');
  const [selectedArticle, setSelectedArticle] = useState<Article | undefined>(undefined);
  const [isSlected, setIsSlected] = useState<boolean>(false);
  const [cookies] = useCookies(['token']);
  const navigate = useNavigate();
  const [isreloadData, setisReloading] = useState(true);

  useEffect(() => {
    if (isreloadData) {
      getAllArticles().then((data_item) => {
        if (data_item.status !== 200) {
          navigate("/");
        }
        setArticles(data_item.articles);
        articlesFiltrees = articles.filter((item: any) =>
            Object.values(item)
                .join('')
                .toLowerCase()
                .includes(recherche.toLowerCase())
        );
        setisReloading(false);
      });

    }

  }, [isreloadData]);

  const getAllArticles = async () => {
    const data  = await client.query({
      query: GET_ALL_ARTICLES,
      context: {
        headers: {
          authorization: "Bearer " + cookies.token,
        },
      }
    });
    return data.data.getAllArticlesPartner;
  };


  const handleChangeRecherche = (event: ChangeEvent<HTMLInputElement>) => {
    setRecherche(event.target.value);
  };

  const handleEditClick = (user: Article) => {
    setIsSlected(true);
    setSelectedArticle(user);
  };
  const handleCloseModal = () => {
    setIsSlected(false);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const numbersOnly = value.replace(/\D/g, '');
    const result = parseInt(numbersOnly, 10);

    if (selectedArticle) {
      setSelectedArticle({
        ...selectedArticle,
        [name]: value,
      });
    }
  };


  let articlesFiltrees = articles.filter((item: any) =>
      Object.values(item)
          .join('')
          .toLowerCase()
          .includes(recherche.toLowerCase())
  );

  const handleUpdateUser = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedArticle) {
      try {
        const price: string = (typeof selectedArticle.price) === 'number' ? selectedArticle.price.toString() as string : selectedArticle.price as string;

        const {data} = await client.mutate({
          mutation: UPDATE_ARTICLE,
          variables: {
            id: selectedArticle.id,
            name: selectedArticle.name,
            price: price,
            image: selectedArticle.image,
            description: selectedArticle.description !== undefined ? selectedArticle.description : "",
            environnement_desc: selectedArticle.environnement_desc !== undefined ? selectedArticle.environnement_desc : "",
            ethical_desc: selectedArticle.ethical_desc !== undefined ? selectedArticle.ethical_desc : "",
          },
          context: {
            headers: {
              authorization: "Bearer " + cookies.token,
            },
          }
        });
        setIsSlected(false);
        setSelectedArticle(undefined);
        if (data.updateArticlePartner.status !== 200) {
          showToast('error', data.devMessage);
        } else {
          showToast('success', data.devMessage);
          setisReloading(true);
        }

      } catch (error) {
        console.log('Error updating user:', error);
        showToast('error', "Update raté");
      }
    }
  };

  const TableCellStyled = styled(TableCell)(() => ({
    fontSize: '1rem',
    border: '1px solid black',
    padding: '8px',
    textAlign: 'center'
  }))

  const displayBodyTable = (items: Article[]) => {
    return items.map((item) => (
        <TableRow key={item.id}>
          {Object.values(item).map((value, index: number) => (
              index === 0 ? null :
                  <TableCellStyled key={index} size='small'>
                    {typeof value === "string" &&
                    (value.startsWith("data:image") || value.match(/\.(jpeg|jpg|gif|png)$/i)) ? (
                        <img
                            style={{ width: "100px", height: "100px", objectFit: "cover" }}
                            src={value}
                            alt="image"
                        />
                    ) : (
                        value
                    )}
                  </TableCellStyled>
          ))}
          <TableCellStyled padding="checkbox">
            <Button variant="outlined" onClick={() => handleEditClick(item)}>
              Modifier
            </Button>
          </TableCellStyled>
        </TableRow>
    ))
  }

  return (
      <Container sx={{ width: '100%' }}>
        <TextField
            label="Rechercher"
            variant="outlined"
            value={recherche}
            onChange={handleChangeRecherche}
            sx={{ marginBottom: '10px' }}
        />
        <TableContainer >
          <Table
              aria-labelledby="tableTitle">
            <TableHead>
              <TableRow >
                {['id', 'name', 'description', "image", "environement description", "ethical description", "price", ''].map((columnName, index) => (
                    <TableCellStyled key={index} size='small' padding='normal'>
                      {columnName}
                    </TableCellStyled>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {displayBodyTable(articlesFiltrees)}
            </TableBody>
          </Table>
        </TableContainer>

        {selectedArticle && isSlected && (
            <Modal open={isSlected}
                   onClose={handleCloseModal}
                   aria-labelledby="modal-title"
                   aria-describedby="modal-description"
            >
              <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    maxHeight: '80vh',
                    overflowY: 'auto',
                  }}
              >
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                  Modifier l'utilisateur
                </Typography>
                <form onSubmit={handleUpdateUser}>
                  <TextField
                      key="nom de l'article"
                      label="nom de l'article"
                      name='name'
                      value={selectedArticle.name}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                  />
                  <TextField
                      key="prix"
                      label="Prix de l'article"
                      name='price'
                      value={selectedArticle.price}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                  />

                  <TextField
                      key="description"
                      label="description de l'article"
                      name='description'
                      value={selectedArticle.description}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                  />

                  <TextField
                      key="environnement_desc"
                      label="environnement description de l'article"
                      name='environnement_desc'
                      value={selectedArticle.environnement_desc}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                  />

                  <TextField
                      key="ethical_desc"
                      label="ethical description de l'article"
                      name='ethical_desc'
                      value={selectedArticle.ethical_desc}
                      onChange={handleInputChange}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                  />

                  <Button type="submit" variant="outlined">Enregistrer</Button>
                </form>
              </Box>
            </Modal>
        )}
      </Container>
  );
}
