import React, { createRef } from 'react';
import './inputs.scss';

type TextInputProps = {
  title?: string;
  placeholder?: string;
  showContent?: boolean;
  width?: number;
  tag?: string;
  myRef?: React.RefObject<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const TextInput: React.FC<TextInputProps> = ({
  title = '',
  placeholder = '',
  showContent = true,
  width = 100,
  tag = '',
  myRef = createRef<HTMLInputElement>(),
  onChange,
}) => {
  return (
    <div style={{ width: `${width}%` }}>
      {title && <h3>{title}</h3>}
      <input
        className={'generic-component'}
        type={showContent ? 'text' : 'password'}
        placeholder={placeholder}
        name={tag}
        ref={myRef}
        style={{ width: `100%` }}
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput;
