import { Box, Typography, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';
import theme from './theme';

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '98%',
        bgcolor: theme.palette.green.dark,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: { xs: 1, sm: 2 },
        py: { xs: 1, sm: 2 },
        flexWrap: 'wrap',
        zIndex: 1000,
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        <Grid>
          <Link href="/" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              Accueil
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Link href="/catalog" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              Catalogue
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Link href="/sitemap" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              Plan du site
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Link href="/legalMentions" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              Mentions légales
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Link href="/cgv" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              CGV
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Link href="/confidentiality" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              Politique de confidentialité
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Link href="/cgu" color="white.main" underline="none">
            <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
              CGU
            </Typography>
          </Link>
        </Grid>
        <Grid>
          <Typography fontSize={{ xs: 8, sm: 10 }} variant="body2" color="white.main">
            LocalShirt © 2024
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          textAlign: { xs: 'center', md: 'right' },
          width: { xs: '100%', md: 'auto' },
        }}
      >
        <Link href="mailto:localshirt.eip@gmail.com" color="white.main" underline="always">
          <Typography fontSize={{ xs: 8, sm: 10 }} variant={'body2'} color="white.main">
            localshirt.eip@gmail.com
          </Typography>
        </Link>
      </Grid>
    </Box>
  );
}
