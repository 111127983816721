import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import AlternativeCard from './alternativeCard';
import Loading from '../../components/loading/Loading';
import BlurryBackground from '../../components/blurryBackground/BlurryBackground';
import { gql } from '@apollo/client';
import client from '../../graphqlApollo';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import ShirtImage from '../../assets/tshirt.svg';

export type Items = {
  title: string;
  photo: string;
  id: string;
  price: number;
  desc: string;
  score: number;
  type: string;
  redirectnumber: number;
  lastshown: Date;
  lastclick: Date;
  ethicalscore: number;
  ecologicalscore: number;
  redirection_url: string;
};

export interface Article {
  id: string;
  name: string;
  redirection_url: string;
  email: string;
  country: {
    name: string;
  };
  material: {
    name: string;
  };
  transport: {
    name: string;
  };
  image: string;
  environnement_desc: string;
  ethical_desc: string;
  price: number;
  ethical_score: number;
  ecological_score: number;
  local_score: number;
}

const Result = () => {
  const [isLoading, setLoading] = useState(false);
  const [filteredItemList, setFilteredItemList] = useState<Items[]>([]);
  const location = useLocation();
  const { url } = location.state || {};
  const { base64Image } = location.state || {};
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!url && !base64Image) {
        navigate('/forbidden');
        return;
      }

      setLoading(true);

      try {
        if (url) {
          // Si l'URL est fournie, on utilise la query `findAlternative`
          const { data } = await client.query({
            query: gql`
              query Alternative($url: String!) {
                findAlternative(URL: $url, likeliness: 0.8, minPrice: 0, maxPrice: 100) {
                  status
                  devMessage
                  articles {
                    id
                    name
                    redirection_url
                    email
                    country {
                      name
                    }
                    material {
                      name
                    }
                    transport {
                      name
                    }
                    image
                    environnement_desc
                    ethical_desc
                    price
                    ethical_score
                    ecological_score
                    local_score
                  }
                }
              }
            `,
            variables: { url },
          });

          const articles = data.findAlternative.articles.map((article: Article) => ({
            id: article.id,
            title: article.name,
            photo: article.image,
            price: article.price,
            desc: `${article.ethical_desc} - ${article.environnement_desc}`,
            score: article.ethical_score,
            type: article.material.name,
            redirectnumber: 0,
            lastshown: new Date(),
            lastclick: new Date(),
            ethicalscore: article.ethical_score,
            ecologicalscore: article.ecological_score,
            redirection_url: article.redirection_url,
          }));
          setFilteredItemList(articles);
        } else if (base64Image) {
          const { data } = await client.query({
            query: gql`
              query AlternativeByImage($base64Image: String!) {
                findAlternativeByImage(
                  image: $base64Image
                  likeliness: 0
                  minPrice: 0
                  maxPrice: 100
                ) {
                  status
                  devMessage
                  articles {
                    id
                    name
                    redirection_url
                    email
                    country {
                      name
                    }
                    material {
                      name
                    }
                    transport {
                      name
                    }
                    image
                    environnement_desc
                    ethical_desc
                    price
                    ethical_score
                    ecological_score
                    local_score
                  }
                }
              }
            `,
            variables: { base64Image },
          });

          const articles = data.findAlternativeByImage.articles.map((article: Article) => ({
            id: article.id,
            title: article.name,
            photo: article.image,
            price: article.price,
            desc: `${article.ethical_desc} - ${article.environnement_desc}`,
            score: article.ethical_score,
            type: article.material.name,
            redirectnumber: 0,
            lastshown: new Date(),
            lastclick: new Date(),
            ethicalscore: article.ethical_score,
            ecologicalscore: article.ecological_score,
            redirection_url: article.redirection_url,
          }));
          setFilteredItemList(articles);
          
          }
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error fetching data:', error);
            } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, base64Image, setLoading, setFilteredItemList, navigate]);

  return (
    <>
      <BlurryBackground />
      <Loading open={isLoading} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
          height: '80vh',
          marginTop: '20vh',
          overflowY: 'auto',
        }}
      >
        {filteredItemList.length === 0 && !isLoading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={ShirtImage}
              alt="No items"
              style={{ maxWidth: '150px', marginBottom: '10px' }}
            />
            <Typography variant="h6" sx={{ color: 'gray', textAlign: 'center', margin: '2vh' }}>
              Nous sommes désolés, mais nous n'avons pas trouvé d'alternative convenable à votre
              vêtement.<br></br>
            </Typography>
          </Box>
        ) : (
          filteredItemList.map((item) => (
            <div key={item.id} style={{ marginBottom: '30px' }}>
              <AlternativeCard
                key={item.id}
                title={item.title}
                type={item.type}
                photo={item.photo}
                id={item.id}
                price={item.price}
                desc={item.desc}
                score={item.score}
                ecologicalscore={item.ecologicalscore}
                ethicalscore={item.ethicalscore}
                redirectnumber={item.redirectnumber}
                lastshown={item.lastshown}
                lastclick={item.lastclick}
                redirection_url={item.redirection_url}
              />
            </div>
          ))
        )}
      </Box>
    </>
  );
};

export default Result;
