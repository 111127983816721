import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';


const isTokenValid = (token: string | undefined): boolean => {
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp === undefined) {
            return false;
        }

        return decodedToken.exp > currentTime;
    } catch (error) {
        return false;
    }
};

const AuthGuard = () => {
    const [cookies, , removeCookie] = useCookies(['token']);
    const navigate = useNavigate();
    const token = cookies.token;

    useEffect(() => {
        if (!isTokenValid(token)) {
            removeCookie('token');
            navigate('/login');
        }
    }, [token, removeCookie, navigate]);

    return isTokenValid(token) ? <Outlet /> : null;
};

export default AuthGuard;
