import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Paper, Typography } from '@mui/material';

interface Event {
    date: string;
    title: string;
    description: string;
}

const CustomTimeline: React.FC = () => {
    const events: Event[] = [
        { date: 'Avril 2023', title: 'Gestion et Processus', description: 'Établissement des règles de vie, prise en main des différents outils' },
        { date: 'Juin 2023', title: 'Maquettage', description: 'Convenir d’un visuel ainsi qu’une charte de couleurs' },
        { date: 'Août 2023', title: 'Base du projet', description: 'Mise en place de la base des 3 parties principales : Web-app, Serveur et Extension' },
        { date: 'Octobre 2023', title: 'Page d’Accueil & Extension', description: 'Mise en place des fonctionnalités de base de ces deux pages' },
        { date: 'Décembre 2023', title: 'Scraping & Analyse d’image', description: 'Première version permettant de récupérer les informations de l’article consulté' },
        { date: 'Février 2024', title: 'Partie pour les Partenaires', description: 'Instaurer la possibilité de se connecter, ajouter et interagir avec des articles' },
        { date: 'Avril 2024', title: 'Version Beta', description: 'Première version fonctionnelle du projet' },
        { date: 'Août 2024', title: 'Révision du projet', description: 'Nettoyage du code, amélioration de l’architecture et gestion' },
        { date: 'Octobre 2024', title: 'Amélioration Analyse d’image', description: 'Récupérer plus d’informations sur l’article consulté (Ex: motifs, coupe)' },
        { date: 'Décembre 2024', title: 'Version Finale App Web', description: 'Finalisation des fonctionnalités pour un lancement de projet' },
        { date: 'Janvier 2025', title: 'Finalisation du projet', description: 'Mise en place de l’accès au site. Téléchargement de l’extension possible.' },
    ];

    const styles = {
        timelineContainer: {
            backgroundColor: '#DCE0D9',
            padding: '20px',
            minHeight: '100vh',
        },
        paper: {
            padding: '6px 16px',
            backgroundColor: '#12372A',
            color: '#FFFFFF',
        },
        timelineDot: {
            backgroundColor: '#CBD6C3', 
        },
        connector: {
            backgroundColor: '#12372A',
        },
        date: {
            color: '#6C7462',
        },
        title: {
            fontWeight: 'bold',
            color: '#FFFFFF',
        },
        description: {
            color: '#FFFFFF',
        },
    };

    return (
        <div style={styles.timelineContainer}>
            <Timeline position="alternate">
                {events.map((event, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent>
                            <Typography variant="body2" style={styles.date}>
                                {event.date}
                            </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot style={styles.timelineDot} />
                            {index < events.length - 1 && <TimelineConnector style={styles.connector} />}
                        </TimelineSeparator>
                        <TimelineContent>
                            <Paper elevation={3} style={styles.paper}>
                                <Typography variant="h6" component="h1" style={styles.title}>
                                    {event.title}
                                </Typography>
                                <Typography style={styles.description}>
                                    {event.description}
                                </Typography>
                            </Paper>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </div>
    );
};

export default CustomTimeline;
